* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;





}

nav {
    width: 100%;
    height: 4em;
    background: linear-gradient(#291636, #03193c);
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    position: fixed;
    padding: 1em;
    box-shadow: 0 0 20px 2px #03193c;
    opacity: 85%;


}

.lefside {
    top: 20px;
}


.prenom {
    color: white;
    position: absolute;
    font-family: 'Quicksand', sans-serif;
    margin-left: 90px;


    top: 20px;
}

/* navigation mobile */

.burger-menu {
    height: 50%;
    width: 4em;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    cursor: pointer;
    margin-top: 12px;


}

.burger-bar {
    width: 2em;
    height: 0.2em;
    background-color: white;
    border-radius: 0.5em;
}

.menu {
    width: 100%;
    height: 100vh;
    background-color: rgb(58, 50, 76);
    position: fixed;
    top: 0;
    z-index: -1;
    border: 4px solid rgb(48, 46, 46);
    color: white;




}

.hidden {
    display: none;
}

.visible {
    display: inherit;
}

/* animations mobile appuie*/

.burger-bar.clicked:nth-child(1) {
    transform: rotate(45deg) translate(0.3em, 0.3em);
    transition: ease-out 0.5s;
}

.burger-bar.clicked:nth-child(3) {
    transform: rotate(135deg) translate(-0.3em, 0.3em);
    transition: ease-out 0.5s;
}

.burger-bar.clicked:nth-child(2) {
    transform: scale(0.01);

}

.burger-bar.unclicked {
    transform: rotate(0) translate(0);
    transition: cubic-bezier(0.175, 0.885, 0.32, 1.275) 0.5s;

}

/* animation ordinateur */

@media screen and (min-width : 913px) {

    .burger-menu {
        display: none;
    }

    .menu {
        display: none;
    }


}

@media screen and (max-width : 912px) {
    .rightside {
        display: none;
    }
}



/* navigation ordinateur */


.menu-horizontal {
    list-style: none;
    display: flex;
    margin-right: 10px;

}

.menu-horizontal>li>a {
    margin-left: 20px;
    color: white;
    text-decoration: none;
    cursor: pointer;
}

.menu-horizontal>li {
    display: flex;
    justify-content: center;
    align-items: center;
}

.menu-horizontal>li:hover {
    font-weight: bold;
    color: whitesmoke
}

.onglets {
    display: flex;
    justify-content: space-between;
}

.rightside {
    margin-top: 5px;

}

.menu-vertical {
    padding-top: 80px;
    list-style: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-left: 0;

}

.menu-vertical>li {
    padding-top: 30px;
    padding-bottom: 15px;
    width: 150px;
    border-bottom: 1px solid rgb(255, 255, 255);
    display: inline;
    cursor: pointer;
    z-index: 4000;

}

.menu-vertical>li>a {
    font-weight: bold;
    cursor: pointer;
    text-decoration: none;

}

.menu-vertical>li>a:hover {
    font-weight: bold;
    color: whitesmoke;
    cursor: pointer;
    position: relative;

}

.menu-vertical>:nth-child(6) {
    border: 0;
}

.navbar {
    position: relative;
    z-index: 4;
}

.bouton-bleu {
    box-shadow: inset 0px 1px 0px 0px #fce2c1;
    background: linear-gradient(to bottom, #33bdef 5%, #019ad2 100%);
    background-color: #33bdef;
    border-radius: 50px;
    color: white;
    border: 1px solid #057fd0;
    display: flex;
    cursor: pointer;
    color: #ffffff;
    font-family: Arial;
    font-size: 15px;
    font-weight: bold;
    padding: 6px 24px;
    text-decoration: none;
    text-shadow: 0px 1px 0px #5b6178;
    align-items: center;
}


.bouton-bleu:hover {
    background: linear-gradient(to bottom, #019ad2 5%, #33bdef 100%);
    background-color: #019ad2;
}

.bouton-bleu:active {
    position: relative;
    top: 1px;
}

@media screen and (min-width: 1036px) {
    .petitCV {
        display: none;
    }
}

@media screen and (max-width: 1035px) {
    .grandCV {
        display: none;
    }
}



.active {
    border-bottom: 2px solid white;

}
.presentation {
    height: 100vh;
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    grid-template-rows: repeat(5, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    background-color: #1F1D2C;
    padding: 0;
    margin: 0;



}

.image {
    height: 100vh;
}

i {
    color: white;
}

.bloc-photo-presentation {
    grid-area: 1 / 5 / 6 / 9;
    display: flex;
    justify-content: center;
    align-items: center;

}

.pos-texte-presentation {
    grid-area: 1 / 1 / 5 / 6;
}

.logos-presentation {
    grid-area: 5 / 1 / 6 / 6;
}


.jesuis {
    font-size: 50px;
    color: white;
}

.img-presentation {
    border: 2px solid black;
    border-radius: 55%;
    width: 45%;
    box-shadow: 0 0 15px 2px black;

}


.logos-presentation {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin: 0 8vw;
}

.texte {
    color: white;
    text-align: left;
    padding-bottom: 4px;
    font-family: 'Open Sans', sans-serif;
    font-weight: bold;

}

.textebloc {
    display: flex;
    align-items: center;
    justify-content: center;

}


.pos-texte-presentation {
    display: flex;
    align-items: center;
    justify-content: center;
}

.Bonjour {
    font-size: 5vw;
}

.etudiant {
    font-size: 2vw;
}

.Prenom {
    font-size: 3vw;
}

@media screen and (max-width:450px) {

    .presentation {
        height: 100vh;
        display: grid;
        grid-template-columns: repeat(7, 1fr);
        grid-template-rows: repeat(11, 1fr);
        grid-column-gap: 0px;
        grid-row-gap: 0px;
        border: 1px solid black;
    }

    .background-presentation {
        grid-area: 1 / 1 / 12 / 8;
        ;
        z-index: -1;
        background-color: #1F1D2C;

    }

    .image {
        height: 100vh;
    }

    i {
        color: white;
    }

    .bloc-photo-presentation {
        grid-area: 2 / 3 / 7 / 6;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;

    }

    .pos-texte-presentation {
        grid-area: 7 / 2 / 10 / 7;

    }

    .bloctexte {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;

    }

    .logos-presentation {
        grid-area: 10 / 4 / 11 / 8;
    }

    .img-presentation {
        border: 2px solid black;
        border-radius: 55%;
        width: 140%;
        box-shadow: 0 0 15px 2px black;


    }

    .texte {
        color: white;
        text-align: center;
        padding-bottom: 4px;
        font-family: 'Open Sans', sans-serif;
        font-weight: bold;

    }

    .Bonjour {
        font-size: 10vw;
    }

    .etudiant {
        font-size: 5vw;
    }

    .Prenom {
        font-size: 6vw;
    }

}
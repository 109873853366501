.competences {
    width: 100%;
    height: 100vh;
    background-color: #1F1D2C;
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: repeat(7, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    padding-top: 10vh;
    overflow-x: hidden;
}

.competences-titre {
    grid-area: 1 / 5 / 2 / 9;
    color: turquoise;
    text-decoration: underline;

}

.bloc2 {
    grid-area: 2 / 10 / 4 / 12;

}

.bloc4 {
    grid-area: 5 / 10 / 7 / 12;
}

.bloccompetences {
    grid-area: 2 / 2 / 7 / 6;
}

.bloc1 {
    grid-area: 2 / 7 / 4 / 9;
}

.bloc3 {
    grid-area: 5 / 7 / 7 / 9;
}

.logo {
    height: 50%;
}

.miniature {
    display: flex;
    flex-direction: column;
    background-color: #262837;
    border: 3px solid #555a80;
    border-radius: 15px;
    align-items: center;
    justify-content: center;
}

.imagelogo {
    width: 10vh;
}

.nomlogiciel {
    color: white;
    font-size: 1.5vh;
}

.desclogiciel {
    font-size: 2vh;
    color: white;
}

.logocss {
    width: 6vh;
}

.reactlogo {
    width: 13vh;
}

.parentheses {
    font-size: 2vh;
}

.bloccompetences {
    justify-content: flex-start;
    width: 100%;
}

.bloccompetences>h1 {
    color: turquoise;
    font-size: 3vh;
}

.bloccompetences>h3 {
    color: white;
    font-size: 2vh;
    padding-top: 5vh;
}

.bloccompetences>ul>li {
    padding: 3% 0;
    font-size: 2vh;
}

.bloctitre {
    grid-area: 1 / 7 / 2 / 12;
    display: flex;
    justify-content: center;
    align-items: center;

}

.bloctitre>h2 {
    font-size: 2vh;
    color: turquoise;
}

@media screen and (max-width:420px) {


    .competences {
        width: 100%;
        height: 100vh;
        background-color: #1F1D2C;
        display: grid;
        grid-template-columns: repeat(7, 1fr);
        grid-template-rows: repeat(9, 1fr);
        grid-column-gap: 0px;
        grid-row-gap: 0px;
        padding-bottom: 3vh;


    }

    .bloctitre {
        display: none;

    }

    .competences-titre {
        grid-area: 1 / 2 / 2 / 7;
        font-size: 2vh;
    }

    .bloctitre>h2 {
        font-size: 2.5vh;

    }

    .bloc2 {
        grid-area: 2 / 5 / 4 / 7;

    }

    .bloc4 {
        grid-area: 5 / 5 / 7 / 7;
    }

    .bloccompetences {
        grid-area: 8 / 2 / 10 / 7;
        height: 250px;
    }

    .bloc1 {
        grid-area: 2 / 2 / 4 / 4;
    }

    .bloc3 {
        grid-area: 5 / 2 / 7 / 4;
    }

    .imagelogo {
        width: 9vh;
    }

    .reactlogo {
        width: 8vh;
    }

    .logocss {
        width: 5.5vh;
    }



    .bloccompetences>h1 {
        color: turquoise;
        font-size: 2vh;

    }

    .bloccompetences>h3 {
        color: white;
        font-size: 2vh;
        padding-top: 5vh;
    }

    .bloccompetences>ul>li {
        padding: 3% 0;
        font-size: 1.5vh;
    }

    .miniature {
        display: flex;
        flex-direction: column;
        background-color: #262837;
        border: 3px solid #555a80;
        border-radius: 15px;
        align-items: center;
        justify-content: center;
    }

}

.listeLangages {
    color: white;
    text-decoration: none;
    list-style: none;
    padding: 0;
}